import axios from "axios";
import qs from "qs";

import api from './api';

const isDevelopmentMode = process.env.NODE_ENV === "development";
// const baseUri = isDevelopmentMode ? "http://172.31.2.21:5000" : "https://apileads.mycarefree.com";
const baseUri = isDevelopmentMode ? "http://localhost:5000" : "https://apileads.mycarefree.com";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

const config = {
  baseURL: baseUri + "/api/",
  paramsSerializer: params => qs.stringify(params, {arrayFormat: "repeat"})
};

const _axios = axios.create(config);
window.api = api;

export {_axios}

