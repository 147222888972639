// import React from "react";
import "./Enrollments.css";

function buildUrl() {
  console.log("--SF Launch");
  // write subdomine here
  // var subdomain = "" || "www";
  // // write site here
  // var site = "unh";
  // // write sfid here
  // var sfid = "";
  // let url = `https://${subdomain}.sunfirematrix.com/app/consumer/${site}/`;
  // let windowSearch = window.location.search;
  //
  // if (sfid) {
  //   if (windowSearch) return url + windowSearch + "&sfid=" + sfid;
  //   else return url + "?sfid=" + sfid;
  // } else return url + windowSearch;
  return "https://unifiedhealthadvisors.com/enroll"
}

// DON'T REMOVE THIS CODE!!!
// export default function Enrollemtns(props) {
//   return (
//     <div id={"enrollemtns"} className="full d-flex flex-column">
//       <iframe title={"sunfire-ember"} id={"sunfire-ember"} frameBorder={"0"}
//               src={buildUrl()}></iframe>
//     </div>
//   );
// }

export { buildUrl };
