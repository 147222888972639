import React, { Component } from "react";
import "./reset.css";
import "./App.css";
import './plugins/axios';
import loadable from "@loadable/component";
import ReactDOM from "react-dom";
import router from "./routes";
import {breakpoint} from "./plugins/breakpointConfig";

const Nav = loadable(() => import(/* webpackChunkName: "Nav" */ './Components/Nav/Nav'));
const Toolbar = loadable(() => import(/* webpackChunkName: "Toolbar" */ './Components/Toolbar/Toolbar'));
const Footer = loadable(() => import(/* webpackChunkName: "Footer" */ './Components/Footer/Footer'));
const Backdrop = loadable(() => import(/* webpackChunkName: "Backdrop" */ './Components/Backdrop/Backdrop'));

class App extends Component {
  state = {
    toolbarOpen: false,
    scrolled: false
  };

  scrollListener = () => {
    const isTop = window.scrollY < 100;
    if (isTop !== true) {
      this.setState({scrolled: true});
    } else {
      this.setState({
        scrolled: false
      });
    }
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.scrollListener);

    window.addEventListener( "resize",()=> breakpoint.breakpointWidth = window.screen.width);
    breakpoint.breakpointWidth = window.screen.width;
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.scrollListener);
    window.removeEventListener("resize",()=> breakpoint.breakpointWidth = window.screen.width);
  };

  toolbarToggleClickHandler = () => {
    this.setState(prevState => {
      return { toolbarOpen: !prevState.toolbarOpen };
    });
  };
  backdropClickHandler = () => {
    this.setState({ toolbarOpen: false });
  };
  handleScrollToElement(e) {
    const tesNode = ReactDOM.findDOMNode(this.refs[e]);
    window.scrollTo({ top: tesNode.offsetTop, behavior: "smooth" });
  }
  toTop = () => {
    this.handleScrollToElement("nav");
  };

  render() {
    let backdrop;
    if (this.state.toolbarOpen) {
      backdrop = <Backdrop backdropClickHandler={this.backdropClickHandler} />;
    }
    return (
      <div className="App" ref="nav">
        <Nav
          toolbarToggleClickHandler={this.toolbarToggleClickHandler}
          scrolled={this.state.scrolled}
          to_top={this.toTop}
        />
        <div id="nav"/>
        <Toolbar
          show={this.state.toolbarOpen}
          backdropClickHandler={this.backdropClickHandler}
          to_top={this.toTop}
        />
        {backdrop}
        {router}
        <Footer to_top={this.toTop}/>
      </div>
    );
  }
}

export default App;
