import React from "react";
import { Switch, Route } from "react-router-dom";

import loadable from "@loadable/component";

const About = loadable(() => import("./Components/About/About"));
const Dental = loadable(() => import("./Components/Dental/Dental"));
const Vision = loadable(() => import("./Components/Vision/Vision"));
const Medicare = loadable(() => import("./Components/Medicare/Medicare"));
const Terms = loadable(() => import("./Components/Terms/Terms"));
// DON'T REMOVE !!!
// import Enrollments from "./Components/Enrollments/Enrollments";

export default (
  <Switch>
    <Route path="/About" component={About} />
    <Route path="/dental" component={Dental} />
    <Route path="/vision" component={Vision} />
    <Route exact path="/" component={Medicare} />
    <Route path="/termsPrivacy" component={Terms} />
  </Switch>
);
