import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import { buildUrl } from "./Components/Enrollments/Enrollments";

// Facebook Pixel
const fbq = window.fbq;
fbq("init", "225246031877134");
fbq("track", "PageView");
const sunfire_url = buildUrl();

export function fbPxTrigger() {
  /**Facebook Pixel Tracker for buttons*/
  // const fbq = window.fbq;
  fbq("track", "ViewContent");
  console.log("Facebook Pixel Tracker sended");
  window.open(sunfire_url, "_blank");
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
